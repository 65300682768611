import React, {Component} from 'react';
import logo from './assets/logo.svg';
import './App.css';
import {Route} from "react-router-dom";
import {AboutUs} from './about_us';
import {Features} from './features';

class App extends Component {

    componentDidMount() {
        window.addEventListener('resize', this.forceUpdate);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.forceUpdate);
    }

    render() {
        return (
            <div id="App">

                <div id="content">
                    <Route exact path="/" render={() => (
                        <div>
                            <div>
                                <img src={logo} className="App-logo" alt="abstract ops logo"/>
                            </div>
                            <div style={{backgroundColor: 'lightGray', height: 1, marginLeft: 100, marginRight: 100}}>

                            </div>
                            <div className="flex flex-auto" id="Home">
                                <Features/>
                                <AboutUs/>
                            </div>
                        </div>
                    )}/>
                </div>
            </div>
        );
    }
}

export default App;
