import React, {Component} from 'react';
import PropTypes from 'prop-types';
import hari from './assets/hari_raghavan.jpg';
import oliver from './assets/oliver_nicholas.jpg';
import michael from './assets/michael_novati.jpg';
import jabu from './assets/jabu_dayton.jpg';

const TeamMember = props => (
    <div>
        <div style={{left: 400, position: 'absolute'}}>
            <img
                style={{borderRadius: '50%', height: 120, width: 120}}
                src={props.memberPhoto}
                alt={props.memberName}/>
        </div>
        <div style={{left: 550, position: 'absolute', textAlign: 'start', height: 120, marginTop: 15}}>
            <div
                style={{fontSize: '20px', fontWeight: 'bold'}}>
                {props.memberName}
            </div>

            <div
                style={{fontSize: '18px', fontStyle: 'italic', marginBottom: 8}}>
                {props.memberRole}
            </div>

            <div
                style={{
                    marginRight: 400,
                    textOverflow: 'ellipsis',
                    wordWrap: 'break-word',
                    overflow: 'hidden',
                    maxHeight: '2.4em',
                    lineHeight: '1.2em'
                }}>
                {props.memberExperience}
            </div>
        </div>
    </div>
);

TeamMember.propTypes = {
    memberName: PropTypes.string.isRequired,
    memberRole: PropTypes.string.isRequired,
    memberPhoto: PropTypes.string.isRequired,
    memberExperience: PropTypes.string.isRequired,
    memberLinkedInUrl: PropTypes.string.isRequired,
};

export class AboutUs extends Component {
    render() {
        return (
            <div className="flex" style={{position: 'relative', marginTop: 50}}>

                <div
                    style={{
                        fontSize: '30px',
                        fontWeight: 'bold',
                        marginTop: 100,
                        textAlign: 'center'
                    }}>
                    Team
                </div>
                <div style={{position: 'relative', top: 50}}>
                    <TeamMember
                        memberName='Hari Raghavan'
                        memberRole='Founder & CEO'
                        memberPhoto={hari}
                        memberExperience='Previously COO at Forge (FKA Equidate, $300M Series B fintech company), and management consultant at BCG.'
                        memberLinkedInUrl='https://www.linkedin.com/in/hraghavan/'/>

                </div>

                <div style={{position: 'relative', top: 200}}>
                    <TeamMember
                        memberName='Oliver Nicholas'
                        memberRole='Founding Advisor and investor'
                        memberPhoto={oliver}
                        memberExperience='Previously Head of Infrastructure, Yelp, and Uber.'
                        memberLinkedInUrl='https://www.linkedin.com/in/bigo/'/>
                </div>

                <div style={{position: 'relative', top: 350}}>
                    <TeamMember
                        memberName='Michael Novati'
                        memberRole='Advisor'
                        memberPhoto={michael}
                        memberExperience='Previously Facebook software engineering, 8 years. Developed core components of Facebook Groups and Feed, and led code-cleanup efforts across the entire company.'
                        memberLinkedInUrl='https://www.linkedin.com/in/michaelnovati/'/>
                </div>

                <div style={{position: 'relative', top: 500}}>
                    <TeamMember
                        memberName='Jabu Dayton'
                        memberRole='Advisor'
                        memberPhoto={jabu}
                        memberExperience='CPO, previously first head of HR at Airbnb, TaskRabbit and CPO at Shyp + Abstract. Presently, HR and People Ops consultant for early stage startups; Jabu HR Inc.'
                        memberLinkedInUrl='https://www.linkedin.com/in/jabudayton/'/>
                </div>
            </div>
        )
    }
}