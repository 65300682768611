import React, {Component} from 'react';
import PropTypes from 'prop-types';
import records_and_compliance from './assets/records_and_compliance.png';
import stack from './assets/stack.png';
import operator_support from './assets/operator_support.png';
import system_of_record from './assets/system_of_record.png';
import integrated_corporate_operations from './assets/integrated_corporate_operations.png';
import './features.css';

const Feature = props => (
    <div style={{height: 250, width: 250, margin: 25}}>

        <div className="flip-container" onTouchStart="this.classList.toggle('hover');">
            <div
                className="flipper"
                style={{justifyContents: 'center'}}>
                <div className="front feature-box-design">
                    <div>
                        <div>
                            <img
                                style={{marginTop: '15px', height: 50, width: 50}}
                                src={props.featureIcon}
                                alt={props.featureDescription}/>
                        </div>
                        <div>
                            <div
                                className="flex flex-center"
                                style={{
                                    fontSize: '20px',
                                    fontWeight: 'bold',
                                    marginTop: 25,
                                    fontFamily: 'alegreya',
                                    height: 125,
                                    textAlign: 'center'
                                }}>
                                {props.featureTitle}
                            </div>
                            <div
                                style={{
                                    fontSize: '18px',
                                    fontStyle: 'italic',
                                    fontFamily: 'alegreya',
                                    lineHeight: '1em'
                                }}>
                                {props.comingSoon}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="back feature-box-design">
                    <div className="hv-spin-in" style={{marginTop: 8}}>
                        <pre
                            style={{
                                fontSize: '18px',
                                fontStyle: 'italic',
                                fontFamily: 'alegreya',
                                width: 200,
                                marginTop: 2,
                                marginBottom: 2,
                                textAlign: 'center',
                                left: '10%',
                                top: '50%',
                                position: 'absolute',
                                transform: 'translateY(-50%)',
                                textOverflow: 'ellipsis',
                                maxHeight: '10em',
                                display: 'inline-block',
                                'white-space': 'pre-wrap',
                                lineHeight: '1em'
                            }}>
                            {props.featureDescription}
                        </pre>
                    </div>
                </div>
            </div>
        </div>
    </div>
);

Feature.propTypes = {
    featureIcon: PropTypes.string.isRequired,
    featureTitle: PropTypes.string.isRequired,
    featureDescription: PropTypes.string.isRequired,
    comingSoon: PropTypes.string,
};

export class Features extends Component {
    render() {
        return (
            <div>
                <div className="flex-row" style={{position: 'relative', marginTop: 25, textAlign: 'center'}}>
                    <Feature
                        featureIcon={records_and_compliance}
                        featureTitle="Records and compliance"
                        featureDescription={"Corporate records and document management\n\nBusiness compliance (liability insurance, corporate filings, etc.)\n\nDevelop and implement policies (HR, expense, equity)"}/>

                    <Feature
                        featureIcon={stack}
                        featureTitle="Setting up / running your ops “stack”"
                        featureDescription={"Pick the stack (tools / providers for your needs)\n\nRun the stack (payments, permissions, manage vendors, etc.)"}/>

                    <Feature
                        featureIcon={operator_support}
                        featureTitle="Operator support"
                        featureDescription={"We have the best possible UI: a person, with years of ops experience\n\nHand off specific needs as they arise (e.g., “we need a data room”, “how do we sponsor an H1B?”)"}/>
                </div>
                <div className="flex-row" style={{position: 'relative'}}>

                    <Feature
                        featureIcon={system_of_record}
                        featureTitle="System of record"
                        comingSoon="(Coming late 2019)"
                        featureDescription={"Your AbstractOps “Layer” serves as the single source of truth to own and organize your company’s information (like Segment, but for corporate data)"}/>

                    <Feature
                        featureIcon={integrated_corporate_operations}
                        featureTitle="Fully integrated corporate operations"
                        comingSoon="(Coming early 2020)"
                        featureDescription={"Your operations integrated with all the familiar tools you already use to run your company (e-signature, payroll, cap table management, etc.)"}/>

                </div>

            </div>
        )
    }
}